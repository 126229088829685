<template>
  <div class="scroll">
    <div class="p-2">
      <slot />
    </div>

    <WidgetFooter v-if="showFooter" class="footer-fixed" />
  </div>
</template>

<script>
export default {
  computed: {
    showFooter() {
      if (this.$route === null || this.$route === undefined) {
        return true;
      }

      const hideFooterList = ['widget-rafflespecial', 'widget-user'];
      return !hideFooterList.includes(this.$route.name);
    },
  },
};
</script>

<style scoped>
.scroll:-webkit-scrollbar {
  -webkit-appearance: none;
  display: none;
}
.scroll {
  overflow-x: hidden;
}

.footer-fixed {
  @apply fixed bottom-0 z-50;
  transform: translate3d(0, 0, 0);
}
</style>
